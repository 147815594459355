<template>
  <div class="info-service">
    <b-card class="card-service border text-center">
      <b-card-title class="name" v-text="data.web_title" />
      <div class="border-bottom"></div>
      <b-card-body class="service-content">
        <div class="hints mr-2">
          <div v-html="description" />
        </div>
        <div class="actions">
          <b-form-checkbox
            @change="(v) => $emit('setDuplicate', v)" :checked="duplicate" class="mb-2"  inline
            v-b-tooltip.hover
      title="When selected, the IMEI/s will be searched in the history and if it is found as a duplicate you will be warned."
      variant="gradient-primary"
             >   
            Duplicate
          </b-form-checkbox>
          <b-form-checkbox
            @change="(v) => $emit('setMail', v)"
            :checked="mail"
            name="check-button"
            inline
          >
            Mail
          </b-form-checkbox>
        </div>
      </b-card-body>
      <app-collapse v-if="sample" accordion type="margin">
        <app-collapse-item title="SAMPLE">
          <div v-html="sample" />
        </app-collapse-item>
      </app-collapse>
      <div class="instant text-primary" v-text="data.process_time"/>
    </b-card>
  </div>
</template>

<script>
import {
  VBTooltip,
  BBadge,
  BCard,
  BCardTitle,
  BCardBody,
  BCardText,
  BCardFooter,
  BFormCheckbox,
  VBToggle,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  props: ["data", "duplicate", "mail"],
  data: () => ({
    sampleModal: false,
  }),
  directives: {
     'b-tooltip': VBTooltip,
     "b-toggle": VBToggle
      },
  computed: {
    description() {
      return this.data.description;
    },
    sample() {
      return this.data.sample || null;
    },
  },
  components: {
    BFormCheckbox,
    BCardTitle,
    BCard,
    BCardBody,
    BCardFooter,
    BCardText,
    BBadge,
    AppCollapse,
    AppCollapseItem,
  },
};
</script>
<style lang="scss" scoped>
.info-service {
  margin: 0 auto;
  max-width: 850px;
  .name {
    text-transform: capitalize;
    font-size: 1.4rem;
  }
  .service-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.card-service {
  position: relative;
  .card-body {
    max-width: 1000px;
  }
}
.hints {
  margin-bottom: 5px;
  > * {
    margin-bottom: 15px;
    color: #727272;
    text-align: left;
    max-width: 400px;
  }
}
.dark-layout {
  .hints {
    > * {
      color: #cdcdcd;
    }
  }
}
.actions {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    flex-direction: row;
    justify-content: flex-start;
  }
}
.instant {
  text-transform: uppercase;
  position: absolute;
  top: -10px;
  right: 25px;
  z-index: 1;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
</style>
