<template>
  <CardHeader>
    <template v-slot:topheader>
      <div>
        <div v-if="!loading">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :pressed="!bulk"
            variant="flat-primary"
            @click="bulk = false"
            pill
          >
            <feather-icon icon="SearchIcon" />
            Order
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :pressed="bulk"
            variant="flat-primary"
            @click="bulk = true"
            pill
          >
            <feather-icon icon="ListIcon" />
            Bulk
          </b-button>
        </div>
        <div class="loading-header" v-else>
          <b-spinner class="mr-2" variant="warning" label="loading.." />
          Loading..
        </div>
      </div>
    </template>
    <template>
      <div v-show="!loading">
        <!-- Check Step -->
        <CheckStep
          :bulk="bulk"
          @handleBulk="(v) => (bulk = v)"
          @setService="(s) => (service = s)"
          @setImei="(s) => (service = s)"
          @submit="submit"
        />
        <!-- Display information -->
        <ServiceInfoStep
          v-if="service != null"
          :data="service"
          :duplicate="duplicate"
          :mail="mail"
          @setDuplicate="(v) => (duplicate = v)"
          @setMail="(v) => (mail = v)"
        />
      </div>
      <div v-show="loading" class="please-wait">
        <h2>{{this.wait_message}}</h2>
        <br><progress></progress>
      </div>
    </template>
  </CardHeader>
</template>

<script>
import CheckStep from "@/components/imei/CheckStep.vue";
import ServiceInfoStep from "@/components/imei/ServiceInfoStep.vue";
import Ripple from "vue-ripple-directive";
import { BButton } from "bootstrap-vue";
import dublicateModal from "@/components/imei/duplicateModal.js";
import CardHeader from "@/components/UI/CardHeader.vue";
import ImeiCheck from "@/services/imei.service.js";
import { mapState, mapGetters } from "vuex";

export default {
  data: () => ({
    loading: false,
    service: null,
    bulk: false,
    wait_message: "Please Wait ..",
   // duplicate: false,
   duplicate : JSON.parse(localStorage.getItem("duplicate")) ?? true,
  //  mail: false,
    mail: JSON.parse(localStorage.getItem("mail")) ?? false,
  }),

  watch: {
    duplicate(newDuplicate) {
      localStorage.duplicate = newDuplicate;
    },
    mail(newMail) {
      localStorage.mail = newMail;
    }
  },
  computed: {
    ...mapGetters(["searchingModel"]),
    ...mapState({ user: "user" })
  },

  watch: {
    searchingModel(newValue, oldValue) {
      if(newValue !== "") {
        this.wait_message = newValue;
      }
    },
  },

  methods: {
    async submit(imei) {
      const { service, bulk, duplicate, mail } = this;
      const formData = {
        imei,
        service_id: service.id,
        duplicate,
        mail,
      };
      const navigate = (response) => {
        this.$router.push({
          name: bulk ? "imei-process-multi" : "imei-process-single",
          params: { imei, service, ...response },
        });
      };
      this.loading = true;
      try {
        const dataRes = !bulk
          ? await ImeiCheck.single(formData, service.price)
          : await ImeiCheck.bulk(formData);
        navigate(!bulk ? dataRes : { orders: dataRes });
      } catch (e) {
        let resp = JSON.parse(e.message);
        let err = {response: {data: resp}};
        err.response.status = resp.status;
        const { response, status, time_diff } = err.response?.data;

        status == "duplicate"
          ? dublicateModal(
              this,
              time_diff,
              formData,
              navigate,
              response,
              service.price
            )
          : ImeiCheck.openPopup(err);
        this.loading = false;
      }
    },
  },
  directives: {
    Ripple,
  },
  components: { CheckStep, ServiceInfoStep, BButton, CardHeader },
};
</script>

<style lang="scss" scopped>
.please-wait {
  text-align: center;
  padding: 95px 0;
}
.loading-header {
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 22px;
}
</style>
