import ImeiCheck from "@/services/imei.service.js";
import { convertHTMLEntity } from "@/global/functions.js";

// For display popup duplicate order
export default async function displayDuplicateWarn(vue, timeDiff, formData, navigate, duplicatedOrder, servicePrice) {
    const result = await vue.$swal({
        title: 'Duplicate Order',
        text: `You have already processed this order ${timeDiff}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, reorder',
        cancelButtonText: 'Show old result',
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
    })
    vue.loading = true;
    if (result.value) {
        await ImeiCheck.single({ ...formData, duplicate: false }, servicePrice)
            .then((data) => navigate(data))
            .catch(() => {
                vue.$swal({
                    title: 'Error',
                    text: `Please try again this service later`,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            })
    };
    // in case User hit Show old result
    if (!result.value && result.dismiss != 'backdrop') {
        vue.$swal({
            title: 'Duplicated Order Result',
            html: convertHTMLEntity(duplicatedOrder),
            icon: 'warning',
            confirmButtonText: 'OK',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-secondary ml-1',
            },
            buttonsStyling: false,
        })
    };
    vue.loading = false;
}
