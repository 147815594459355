<template>
  <div class="select-v2">
    <b-input-group>
      <b-input-group-prepend class="icon" is-text>
        <feather-icon :icon="icon" />
      </b-input-group-prepend>
      <!-- Mobile Select -->
      <b-form-select
        class="d-sm-block d-md-none form-control mob-select"
        :value="value"
        @change="(id) => selectItem({ id })"
        :options="[
          { id: null, [label]: 'Please select an Service' },
          ...listWithPrice,
        ]"
        :text-field="label"
        value-field="id"
        :placeholder="placeholder"
      />
      <!-- Desktop Select -->
      <v-select
        class="in-desktop d-none d-md-flex"
        :value="selectedOption"
        @input="(item) => selectItem({ item })"
        :label="label"
        :options="listWithPrice"
        :placeholder="placeholder"
      >
        <template v-slot:no-options>
          <div v-if="!list">Loading ...</div>
        </template>
      </v-select>
    </b-input-group>
  </div>
</template>

<script>
import {
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormInput,
  BListGroup,
  BListGroupItem,
  BFormSelect,
  BProgress,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  props: ["label", "value", "list", "icon", "price", "placeholder"],
  computed: {
    // Selected Option
    selectedOption() {
      const item = this.getItem(this.value);
      return (
        item && {
          ...item,
          [this.label]: this.getPrice(item) + item[this.label],
        }
      );
    },
    // Options with prices
    listWithPrice() {
      if (!this.price) return this.list;
      // return label with price
      return (
        this.list?.map((item) => ({
          ...item,
          [this.label]: this.getPrice(item) + item[this.label],
        })) || []
      );
    },
  },
  methods: {
    getPrice(item) {
      if (!item) return;
      const fPrice = Number.parseFloat(item.price).toFixed(2);
      return this.price ? `$${fPrice} - ` : "";
    },
    getItem(id) {
      if (!Number.isInteger(id)) return null;
      return this.list.find((item) => item.id == id);
    },
    selectItem({ item, id }) {
      //   const finalItem = item ? this.getItem(item.id);
      this.$emit(
        "input",
        Number.isInteger(item?.id) ? item.id : Number.isInteger(id) ? id : null
      );
    },
  },
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BListGroup,
    BListGroupItem,
    BFormSelect,
    BProgress,
    vSelect,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.select-v2 {
  .mob-select {
    text-align-last: center;
  }
  .form-control {
    border-left: none;
  }
  .v-select {
    height: 37px;
    background-color: transparent;
    padding: 0;
    border: none;
    flex: 1;
    .vs__search {
      text-align: center;
      position: absolute;
      left: 0;
      top: -3px;
      width: 100%;
      height: 110%;
    }
    .vs__selected {
      width: 100%;
      align-items: center;
      justify-content: center;
    }
    > * {
      box-shadow: none !important;
      border-radius: 0 7px 7px 0;
      border-left: none;
      flex: 1;
    }
    .vs__actions svg {
      fill: #acacac;
    }
  }
}
</style>
 