import vue from "vue";
import api from "@axios";
import store from "@/store";
import { convertHTMLEntity } from "@/global/functions.js";

// Check/Order service "IMEI CHECK"
class ImeiCheck {
    // Single check
    static async single(formData, servicePrice) {
        // const { data } = await api.post("/imei/single", formData)
        // const { data } = await api.get("https://imeistatuscheck.com/test2.php")

        return new Promise(function(resolve, reject) {
            var xhr = new XMLHttpRequest();
            store.commit("SET_SEARCHING_MODEL", "Please wait ....");
            xhr.onreadystatechange = function() {
                if (this.readyState == 4){
                    if(this.responseText.includes('<|>')) {
                        try {
                            let res = this.responseText.split('<|>');
                            let actual_response = JSON.parse(res[1]);
                            resolve(actual_response);
                            store.commit("HANDLE_CREDIT", -servicePrice);
                        } catch (e) {
                            return false;
                        }
                    } else if (this.status == 403) {
                        // handle 403 error here
                            let res = this.responseText.split('<|>');
                            let actual_response2 = JSON.parse(res[1]);
                            alert(actual_response2);
                            return reject(new Error(actual_response2));
                    }else{
                        if(this.responseText !== "") {
                            resolve(this.responseText); //added by me
                        }
                    }

                } else if (this.readyState > 2){
                    if(!this.responseText.includes('<|>')) {
                        let res = JSON.parse(this.responseText);
                        store.commit("SET_SEARCHING_MODEL", convertHTMLEntity(res.text));            
                    }
                }
            };
            xhr.open("POST", "https://y.imeicheck.com/echo_responses.php", true);
            // xhr.open("post", "http://localhost/echo_responses.php", true);
            xhr.setRequestHeader("show_model","yes");
            xhr.setRequestHeader(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );
            xhr.send(JSON.stringify(formData));
        });
    }
    static async bulk({ imei, service_id, mail, ...rest }) {
        const imeis = [...new Set(imei.split("\n"))].filter((e) => e.trim());
        const { data } = await api.post("/imei/bulk", { imeis, service_id, mail, ...rest })
        return data
    }
    // Open POPUP in error case
    static async openPopup(e) { // e = error response
        const { response: resErr, popup, errors } = e.response?.data
        const error =
            e.response?.status === 403 ? resErr :
                e.response?.status === 422
                    ? Object.values(errors)[0][0]
                    : "Server error..";

        console.log(e.response?.data);
        (popup || e.response?.status === 422) && await vue.prototype.$swal({
            title: 'Error',
            html: convertHTMLEntity(error),
            icon: 'error',
            confirmButtonText: 'Ok, understand',
            customClass: {
                confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
        })
    }
}

export default ImeiCheck